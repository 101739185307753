import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faBars, faEnvelope, faRotateLeft, faCube } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';

import oplogo from './assets/favicon.png'
import Product1 from './assets/product3.png'
import Product2 from './assets/product2.png'
import Product3 from './assets/product4.png'
import Product4 from './assets/product5.png'
import Product5 from './assets/product1.png'
import Product6 from './assets/product6.png'
import Product7 from './assets/product7.png'
import Product8 from './assets/product8.png'
import Product9 from './assets/product9.png'
import Product10 from './assets/product10.png'
import Product11 from './assets/product11.png'
import Product12 from './assets/product12.png'
import Product13 from './assets/product13.png'
import Product14 from './assets/product14.png'




import 'datejs';
import './App.css';

function Circle({ color, onClick }) {//make video collage for facebook ads
  return (
    <div
      className="circle"
      style={{ backgroundColor: color }}
      onClick={onClick}
    ></div>
  );
}

const CongratulatoryModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay-2">
      <div className="modal-content-2">
        <h2>Submitted Successfully!</h2>
        <p>We will get back to you within 2 business days.</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

function Review({ name, review, date }) {
  // Create an array of 5 stars
  const stars = Array(5).fill('★');

  return (
    <div className="review">
      <h4>{name}</h4>
      <p>{review}</p>
      <div>
        {/* Display star symbols */}
        {stars.map((star, index) => (
          <span key={index}>{star}</span>
        ))}
      </div>
      <p>Date: {date}</p>
    </div>
  );
}



function App() {

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedPanType, selSelectedPanType] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const [email, setEmail] = useState('');
  const [isOpenModule, setIsOpenModule] = useState(false);
  const [stateSelectedcategory, setStateSelectedcategory] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    description: '',
    category: 'Double Second'
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isFixed, setIsFixed] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const minMax = useMediaQuery({ query: '(max-width: 900px)' })

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Check if the scroll position is greater than or equal to a certain value (e.g., 200)
      if (scrollPosition >= 400) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    const calculateDeliveryDate = () => {
      let fromDate = Date.today().add(3).days();
      if (fromDate.is().saturday() || fromDate.is().sunday()) {
        fromDate = fromDate.next().monday();
      }

      let toDate = Date.today().add(8).days();
      if (toDate.is().saturday() || toDate.is().sunday()) {
        toDate = toDate.next().monday();
      }

      setFromDate(fromDate.toString('ddd, MMM dS'));//'dddd, MMMM dS'
      setToDate(toDate.toString('ddd, MMM dS'));//'dddd, MMMM dS'
    };

    calculateDeliveryDate();
  }, []);

  // useEffect(() => {
  //  alert(stateSelectedcategory)
  // }, [stateSelectedcategory]);





  const toggleModal = () => {
    setIsOpenModule(!isOpenModule);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };
  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (color) => {
    setSelectedColor(color);
  };

  const handleBuyNowClick = () => {
    // Add functionality to handle buy now click
    console.log('Buy Now clicked!');
    window.open('https://buy.stripe.com/fZe4i8e99gg99BmdQU', "_blank", "noreferrer");

  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    // Here you can handle the form submission
    // console.log('Form submitted:' + formData);
    // alert(stateSelectedcategory)

    const name = formData.name
    const email = formData.email
    const phoneNumber = formData.phone
    const topic = stateSelectedcategory
    const description = formData.description

    axios.post('https://us-central1-uyana-base.cloudfunctions.net/contactFormAlert', { name, email, phoneNumber, topic, description })//the reason we are not sending to a database is because a datebase writing would need premission

    setIsModalOpen(true);
    // alert('Your message has been submitted successfully!, We will get p')
    // Reset form fields
    setFormData({
      name: '',
      email: '',
      phone: '',
      description: '',
    });
    setStateSelectedcategory('')
    // Close the modal
    toggleModal();
  };




  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="App">

      {minMax ? (
        <header className="header-mobile">
          <div style={{ marginTop: '2%' }}>
            <img src={oplogo} alt="Logo" className="logo-mobile" />
          </div>

          <div style={{ marginTop: '2%', marginRight: '2%' }}>
            <h3 className='name-main-text'>OP Steel Pan</h3>
          </div>

        </header>
      ) : (
        <header className="header">
          <div style={{ marginLeft: '2%', marginTop: '2%' }}>
            <img src={oplogo} alt="Logo" className="logo" />
          </div>

          <div style={{ marginTop: '2%' }}>
            <h2 className='name-main-text'>OP Steel Pan</h2>
          </div>

          <div />
        </header>
      )}
      {minMax ? (
        <div>

          <div>
            <Carousel showStatus={false} >
              <div>
                <img src={Product1} alt="Product 1" />
                {/* <p className="legend">Product 1</p> */}
              </div>
              <div>
                <img src={Product2} alt="Product 2" />
                {/* <p className="legend">Product 2</p> */}
              </div>
              <div>
                <img src={Product3} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product4} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product5} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product6} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product7} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product8} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product9} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product10} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product11} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product12} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product13} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
            </Carousel>
          </div>

          <div style={{ marginLeft: '5%', marginRight: '5%' }}>

            <h2 className="main-text">Lead Tenor Steelpan</h2>

            <p>Instruments</p>

            <div className="button-row">
              {selectedPanType === 0 ? <button className="active-outline-button">Tenor</button> : <button className="outline-button">Tenor</button>}
              <button className="outline-button" onClick={() => {
                toggleModal()
                setStateSelectedcategory('Double Tenor')
              }} >Double Tenor</button>
              <button className="outline-button" onClick={() => {
                toggleModal()
                setStateSelectedcategory('Double Second')
              }}>Double Second</button>
              <button className="outline-button" onClick={() => {
                toggleModal()
                setStateSelectedcategory('Tenor Bass')
              }}>Tenor Bass</button>
              <button className="outline-button" onClick={() => {
                toggleModal()
                setStateSelectedcategory('Triple Cello')
              }}>Triple Cello</button>
              <button className="outline-button" onClick={() => {
                toggleModal()
                setStateSelectedcategory('Double Gitta')
              }} >Double Gitta</button>
              <button className="outline-button" onClick={() => {
                toggleModal()
                setStateSelectedcategory('Low Bass')
              }}>Low Bass</button>
              <button className="outline-button" onClick={() => {
                toggleModal()
                setStateSelectedcategory('5 Piece Set')
              }} >5 Piece Set</button>
              <button className="outline-button" onClick={() => {
                toggleModal()
                setStateSelectedcategory('10 Piece Set')
              }} >10 Piece Set</button>
              <button className="outline-button" onClick={() => {
                toggleModal()
                setStateSelectedcategory('20 Piece Set')
              }} >20 Piece Set</button>
              <button className="outline-button" onClick={() => {
                toggleModal()
                setStateSelectedcategory('Custom Set')
              }} >Custom Set</button>
            </div>

            {/* <h4>Color Edition</h4>

               <div className="container">
              <Circle color="blue" onClick={() => handleClick('blue')} />
             <Circle color="red" onClick={() => handleClick('red')} />
              <Circle color="green" onClick={() => handleClick('green')} />
             <Circle color="yellow" onClick={() => handleClick('yellow')} />
               {selectedColor && (
             <p>You selected {selectedColor} color.</p>
                  )}
                  </div> */}

            <h3>US $999.00</h3>
            <p className="price-cut">Was US $1,300.00</p>
            <p>Save US $200 (12% off)</p>

            <p>You'll see our shipping options and be able to adjust the quantity at checkout</p>

            <p>Estimated Delivery: <strong>{fromDate} - {toDate}</strong></p>
            <p>US $125.00 shipping</p>

            <div style={{ marginTop: '5%' }}>
              <button className="buy-now-button" onClick={handleBuyNowClick}>
                Check Out
              </button>
            </div>

            <p style={{ textAlign: 'center' }}>Tight Inventory! Limited supply left</p>

            <h4>What's included</h4>
            <ul>
              <li>Steel Pan(Instrument)</li>

              <li>Pair of Sticks(Mallet)</li>

              <li>Instrument Stand</li>
            </ul>

            <div>
              <div className="horizontal-line"></div>

              <h4><FontAwesomeIcon icon={faCube} /> Details</h4>

              <p>Built to last a lifetime, the Tenor Steelpan is used to play the melody, perfect for Solo Playing or with an ensemble.</p>

              <p>Great for home recreational music making for both kids and adults, it can be played with other instruments, poolside jamming,
                classroom music education, music therapy, worship music, etc</p>

              <p>Our lead steelpans have clarity of tone, which until now has not been associated with steelpans. They are designed using the
                circle of fifths and our invisible groove system.  It is spray-painted, providing a beautiful and durable finish. </p>

              <div className="dropdown">
                <div onClick={toggleDropdown} className="view-more-details-toggle">
                  More details
                </div>
              </div>

              {isOpen && (
                <div>
                  <h4>From the Manufacturer</h4>

                  <p>The steel pan is for all ages. Produces pleasant tropical musical sounds. Made in Guyana, designed and manufactured
                    by OP Steel Pan (Music) Nation Has been the leading supplier of quality steelpans and accessories for over thirty (30) years. </p>

                  <p>We have tirelessly pursued the best techniques and most innovative technologies in pan construction. Two vitally important breakthroughs
                    in crafting a quality steelpan are the “Invisible Groove Method” and Sequential Note Placement Design, which enabled us to produce steelpan
                    instruments with precision, attention to detail, and purity unheard of until now, innovating the Steel Pan Art Form. </p>

                  <p> If you have any questions before ordering, you can call or WhatsApp +592 677 0260 or + 592 704 3522.</p>
                </div>
              )}

              <div className="horizontal-line"></div>

              <div className="dropdown" onClick={toggleDropdown1}>
                <h4 onClick={toggleDropdown1} className="dropdown-toggle">
                  <FontAwesomeIcon icon={faRotateLeft} />   Shipping & Return
                </h4>
                <div className="icon-drop" onClick={toggleDropdown1}>
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              </div>

              {isOpen1 && (
                <div className="dropdown-content">
                  <p>Standard shipping Is US $175.00 and 6-Months returns for all purchases. </p>
                  <p>Please allow 2-3 business days for standard processing before your order ships</p>
                </div>
              )}

              <div className="horizontal-line" ></div>

              <div className="dropdown" onClick={toggleDropdown2}>
                <h4 onClick={toggleDropdown2} className="dropdown-toggle">
                  ★ Customer Reviews(6)
                </h4>
                <div className="icon-drop" onClick={toggleDropdown2}>
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              </div>

              {isOpen2 && (
                <div className="reviews">

                  <Review
                    name="Stephen Graham"
                    review="As someone who's been playing Pan for years,
                     I'm always on the lookout for instruments that offer both great sound and affordability.
                      This musical instrument exceeded my expectations! It has a rich, warm tone and stays in tune remarkably well. "
                    date="May 10, 2024"
                  />
                  <Review
                    name="Heather Smith"
                    review="I'm so glad I stumbled upon this one.
                     It's perfect for jam sessions with friends or just noodling around at home. The sound projection is impressive for its price,
                      and the build feels sturdy!"
                    date="April 12, 2024"
                  />
                  <Review
                    name="Austin Thomas"
                    review="Absolutely thrilled with my new steel pan! The sound quality is exceptional,
                     and the craftsmanship is top-notch. Whether I'm strumming along to my favorite songs or
                      composing my own melodies, the instrument never fails to impress. Highly recommended for both beginners and seasoned players!"
                    date="March 19, 2024"
                  />
                  <Review
                    name="Michael Brown"
                    review="The steel pan exceeded all my expectations! The build quality is top-notch, 
                    and the attention to detail is evident in every aspect, from the fretwork to the tuning stability.
                     Whether I'm strumming softly or shredding through solos, it delivers unparalleled sound clarity and resonance. 
                     It's quickly become my go to instrument for recording and performing."
                    date="March 12, 2024"
                  />
                  <Review
                    name="Emily Davis"
                    review="I purchased this instrument as a gift for my daughter, who's just starting her journey with music.
                     She absolutely loves it! The sound quality is impressive for a beginner's instrument, and it's motivating her to practice more and more every day.
                      A fantastic purchase overall!"
                    date="February 26, 2024"
                  />
                  <Review
                    name="David Wilson"
                    review="I'm a professional musician, and I've played countless instruments throughout my career.
                     This musical steel pan stands out for its exceptional playability and versatility. 
                     Whether I'm performing on stage or recording in the studio, it delivers consistently outstanding sound. 
                     Plus, its sleek sound and tone make it a joy to play for hours on end. I couldn't be happier with my purchase!"
                    date="February 21, 2024"
                  />
                </div>
              )}



            </div>

          </div>
        </div>
      ) : (
        <div >

          <div className={isFixed ? 'fixed-container' : 'unfixed-container'}>
            <Carousel showStatus={false}>
              <div>
                <img src={Product1} alt="Product 1" />
                {/* <p className="legend">Product 1</p> */}
              </div>
              <div>
                <img src={Product2} alt="Product 2" />
                {/* <p className="legend">Product 2</p> */}
              </div>
              <div>
                <img src={Product3} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product4} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product5} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product6} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product7} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product8} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product9} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product10} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product11} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product12} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product13} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
              <div>
                <img src={Product14} alt="Product 3" />
                {/* <p className="legend">Product 3</p> */}
              </div>
            </Carousel>
          </div>

          <div className="container-inline">

            <h1 className="main-text">Lead Tenor Steelpan</h1>

            <h4>Instruments</h4>

            <div className="button-row">
              {selectedPanType === 0 ? <button className="active-outline-button">Tenor</button> : <button className="outline-button">Tenor</button>}
              <button className="outline-button" onClick={() => {

                setStateSelectedcategory('Double Tenor')
                toggleModal()
              }} >Double Tenor</button>
              <button className="outline-button" onClick={() => {

                setStateSelectedcategory('Double Second')
                toggleModal()
              }}>Double Second</button>
              <button className="outline-button" onClick={() => {

                setStateSelectedcategory('Tenor Bass')
                toggleModal()
              }}>Tenor Bass</button>
              <button className="outline-button" onClick={() => {

                setStateSelectedcategory('Triple Cello')
                toggleModal()
              }}>Triple Cello</button>
              <button className="outline-button" onClick={() => {

                setStateSelectedcategory('Double Gitta')
                toggleModal()
              }} >Double Gitta</button>
              <button className="outline-button" onClick={() => {

                setStateSelectedcategory('Low Bass')
                toggleModal()
              }}>Low Bass</button>
              <button className="outline-button" onClick={() => {

                setStateSelectedcategory('5 Piece Set')
                toggleModal()
              }} >5 Piece Set</button>
              <button className="outline-button" onClick={() => {

                setStateSelectedcategory('10 Piece Set')
                toggleModal()
              }} >10 Piece Set</button>
              <button className="outline-button" onClick={() => {

                setStateSelectedcategory('20 Piece Set')
                toggleModal()
              }} >20 Piece Set</button>
              <button className="outline-button" onClick={() => {

                setStateSelectedcategory('Custom Set')
                toggleModal()
              }} >Custom Set</button>
            </div>

            {/* <h4>Color Edition</h4>

               <div className="container">
              <Circle color="blue" onClick={() => handleClick('blue')} />
             <Circle color="red" onClick={() => handleClick('red')} />
              <Circle color="green" onClick={() => handleClick('green')} />
             <Circle color="yellow" onClick={() => handleClick('yellow')} />
               {selectedColor && (
             <p>You selected {selectedColor} color.</p>
                  )}
                  </div> */}

            <h3>US $999.00</h3>
            <p className="price-cut">Was US $1,300.00</p>
            <p>Save US $200 (12% off)</p>

            <p>You'll see our shipping options and be able to adjust the quantity at checkout</p>

            <p>Estimated Delivery: <strong>{fromDate} - {toDate}</strong></p>
            <p>US $125.00 shipping</p>

            <div style={{ marginTop: '5%' }}>
              <button className="buy-now-button" onClick={handleBuyNowClick}>
                Check Out
              </button>
            </div>

            <p style={{ textAlign: 'center' }}>Tight Inventory! Limited supply left</p>

            <h4>What's included</h4>
            <ul>
              <li>Steel Pan(Instrument)</li>

              <li>Pair of Sticks(Mallet)</li>

              <li>Instrument Stand</li>
            </ul>

            <div>
              <div className="horizontal-line"></div>

              <h4><FontAwesomeIcon icon={faCube} /> Details</h4>

              <p>Built to last a lifetime, the Tenor Steelpan is used to play the melody, perfect for Solo Playing or with an ensemble.</p>

              <p>Great for home recreational music making for both kids and adults, it can be played with other instruments, poolside jamming,
                classroom music education, music therapy, worship music, etc</p>

              <p>Our lead steelpans have clarity of tone, which until now has not been associated with steelpans. They are designed using the
                circle of fifths and our invisible groove system.  It is spray-painted, providing a beautiful and durable finish. </p>

              <div className="dropdown">
                <div onClick={toggleDropdown} className="view-more-details-toggle">
                  More details
                </div>
              </div>

              {isOpen && (
                <div>
                  <h4>From the Manufacturer</h4>

                  <p>The steel pan is for all ages. Produces pleasant tropical musical sounds. Made in Guyana, designed and manufactured
                    by OP Steel Pan (Music) Nation Has been the leading supplier of quality steelpans and accessories for over thirty (30) years. </p>

                  <p>We have tirelessly pursued the best techniques and most innovative technologies in pan construction. Two vitally important breakthroughs
                    in crafting a quality steelpan are the “Invisible Groove Method” and Sequential Note Placement Design, which enabled us to produce steelpan
                    instruments with precision, attention to detail, and purity unheard of until now, innovating the Steel Pan Art Form. </p>

                  <p> If you have any questions before ordering, you can call or WhatsApp +592 677 0260 or + 592 704 3522.</p>
                </div>
              )}

              <div className="horizontal-line"></div>

              <div className="dropdown" onClick={toggleDropdown1}>
                <h4 onClick={toggleDropdown1} className="dropdown-toggle">
                  <FontAwesomeIcon icon={faRotateLeft} />   Shipping & Return
                </h4>
                <div className="icon-drop">
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              </div>

              {isOpen1 && (
                <div className="dropdown-content">
                  <p>Standard shipping Is US $175.00 and 6-Months returns for all purchases. </p>
                  <p>Please allow 2-3 business days for standard processing before your order ships</p>
                </div>
              )}

              <div className="horizontal-line" ></div>

              <div className="dropdown" onClick={toggleDropdown2}>
                <h4 onClick={toggleDropdown2} className="dropdown-toggle">
                  ★ Customer Reviews(6)
                </h4>
                <div className="icon-drop">
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              </div>

              {isOpen2 && (
                <div className="reviews">

                  <Review
                    name="Stephen Graham"
                    review="As someone who's been playing Pan for years,
                     I'm always on the lookout for instruments that offer both great sound and affordability.
                      This musical instrument exceeded my expectations! It has a rich, warm tone and stays in tune remarkably well. "
                    date="May 10, 2024"
                  />
                  <Review
                    name="Heather Smith"
                    review="I'm so glad I stumbled upon this one.
                     It's perfect for jam sessions with friends or just noodling around at home. The sound projection is impressive for its price,
                      and the build feels sturdy!"
                    date="April 12, 2024"
                  />
                  <Review
                    name="Austin Thomas"
                    review="Absolutely thrilled with my new steel pan! The sound quality is exceptional,
                     and the craftsmanship is top-notch. Whether I'm strumming along to my favorite songs or
                      composing my own melodies, the instrument never fails to impress. Highly recommended for both beginners and seasoned players!"
                    date="March 19, 2024"
                  />
                  <Review
                    name="Michael Brown"
                    review="The steel pan exceeded all my expectations! The build quality is top-notch, 
                    and the attention to detail is evident in every aspect, from the fretwork to the tuning stability.
                     Whether I'm strumming softly or shredding through solos, it delivers unparalleled sound clarity and resonance. 
                     It's quickly become my go to instrument for recording and performing."
                    date="March 12, 2024"
                  />
                  <Review
                    name="Emily Davis"
                    review="I purchased this instrument as a gift for my daughter, who's just starting her journey with music.
                     She absolutely loves it! The sound quality is impressive for a beginner's instrument, and it's motivating her to practice more and more every day.
                      A fantastic purchase overall!"
                    date="February 26, 2024"
                  />
                  <Review
                    name="David Wilson"
                    review="I'm a professional musician, and I've played countless instruments throughout my career.
                     This musical steel pan stands out for its exceptional playability and versatility. 
                     Whether I'm performing on stage or recording in the studio, it delivers consistently outstanding sound. 
                     Plus, its sleek sound and tone make it a joy to play for hours on end. I couldn't be happier with my purchase!"
                    date="February 21, 2024"
                  />
                </div>
              )}



            </div>

          </div>

        </div>
      )}
      <div  >

        <div className="horizontal-line" style={{ marginTop: '8%' }} ></div>
        {/* 
        <h4>Subscribe to our email list</h4>
        <div className="dropdown">
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
              style={{ width: '300px', padding: '10px' }} // Adjust width and padding as needed

            />
          </form>
          <div type="submit">
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
        </div> */}

        {isOpenModule && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={toggleModal}>&times;</span>
              <h2 style={{ textAlign: 'center' }}>Get a Quote</h2>
              <form onSubmit={handleSubmit1}>
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="input-bottom-border"
                />
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="input-bottom-border"
                />
                <label htmlFor="phone">Phone:</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="input-bottom-border"
                />
                <label htmlFor="category">Category:</label>
                {/* <select
                  id="category"
                  name="category"
                value={stateSelectedcategory}
                  required
                  className="input-bottom-border"
                > */}
                <input
                  id="category"
                  name="category"
                  value={stateSelectedcategory}
                  required
                  className="input-bottom-border"
                  disabled={true}
                />
                {/* <option  onClick={() => setStateSelectedcategory('Tenor')} >Tenor</option>
                  <option  onClick={() => setStateSelectedcategory('Double Tenor')}>Double Tenor</option>
                  <option  onClick={() => setStateSelectedcategory('Double Second')}>Double Second</option>
                  <option onClick={() => setStateSelectedcategory('Tenor Bass')} >Tenor Bass</option>
                  <option onClick={() => setStateSelectedcategory('Triple Cello')} >Triple Cello</option>
                  <option onClick={() => setStateSelectedcategory('Double Gitta')} >Double Gitta</option>
                  <option  onClick={() => setStateSelectedcategory('Low Bass')} >Low Bass</option>
                  <option onClick={() => setStateSelectedcategory('5 Piece Set')} >5 Piece Set</option>
                  <option  onClick={() => setStateSelectedcategory('10 Piece Set')} >10 Piece Set</option>
                  <option  onClick={() => setStateSelectedcategory('20 Piece Set')} >20 Piece Set</option>
                  <option onClick={() => setStateSelectedcategory('Custom Set')} >Custom Set</option> */}
                {/* </select> */}

                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                  className="input-bottom-border"
                ></textarea>
                <button type="submit" className="gradient-button" >Submit</button>
              </form>
            </div>
          </div>

        )}
      </div>

      <CongratulatoryModal isOpen={isModalOpen} onClose={handleCloseModal} />


      <footer className="footer">
        {/* <div className="social-icons">
          <a href="https://www.facebook.com">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://www.instagram.com">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div> */}

        <p>@2024 Op Steel Pan</p>
        {/* <p>Yana Plus LLC</p> */}
      </footer>

    </div>

  );
}

export default App;

